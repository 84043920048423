import { LocalStorageKeys } from "@/enums";
import LocalStorage from "@/lib/customStorage";
import { authMixin, helperMixin } from "@/mixins";
import { defineComponent } from "vue";
import Auth from "@/components/Auth.vue";
import { useRouter } from "@/lib/router";
export default defineComponent({
    components: {
        Auth,
    },
    mixins: [authMixin(), helperMixin],
    data() {
        return {
            resendTimeout: 0,
        };
    },
    mounted() {
        var _a;
        if (this.authStore.state.user.email_verified_at) {
            useRouter().push({
                name: this.RouteNames.Dashboard,
            });
            return;
        }
        this.setPageTitle(this.trans("menu.verify_email"));
        let lastResend = (_a = LocalStorage.getInstance().get(LocalStorageKeys.LastResendEmail)) !== null && _a !== void 0 ? _a : 1;
        if (lastResend) {
            this.resendTimeout =
                // we want to 60s
                60 -
                    // different last sent - now time
                    // as second
                    Math.floor((new Date().getTime() - new Date(lastResend).getTime()) / 1000);
            this.resendCounter();
        }
        else {
            this.submit();
        }
    },
    methods: {
        async submit() {
            this.loading = true;
            await this.authHttp.resendEmailVerification();
            LocalStorage.getInstance().set(LocalStorageKeys.LastResendEmail, String(new Date()));
            this.resendTimeout = 60;
            this.resendCounter();
        },
        resendCounter() {
            if (this.resendTimeout) {
                this.loading = true;
                let interval = setInterval(() => {
                    this.resendTimeout--;
                    if (this.resendTimeout <= 0) {
                        clearInterval(interval);
                        LocalStorage.getInstance().remove(LocalStorageKeys.LastResendEmail);
                        this.loading = false;
                    }
                }, 1000);
            }
        },
    },
});
